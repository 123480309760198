<template>
  <v-card v-if="selectedUser" style="width: 100%;">
    <v-card-title class="userCardTitle">
      <div class="userCardTitle-id">{{selectedUser.id}}</div>
      <div class="userCardTitle-name">{{selectedUser.name}}</div>
      <v-btn
        class="userCardTitle-copy mr-4 elevation-0"
        outlined
        color="rgba(0,0,0,.87)"
        @click="copyAccountDialog = true"
      >
        <v-icon small class="mr-2">
          mdi-content-copy
        </v-icon>
        Копировать
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="ml-0 mr-1"
          >
            <v-icon class="userCardTitle-menu ml-0">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list style="cursor: pointer">
          <v-list-item disabled>
            <v-list-item-title
              ><v-icon class="mr-2">mdi-hail</v-icon>Поприветствовать
            </v-list-item-title>
          </v-list-item>
          <v-list-item disabled @click="newAccountsDialog = true">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-account-multiple-plus-outline</v-icon
              >Несколько по образцу</v-list-item-title>
          </v-list-item>
          <v-list-item @click="kickUser(selectedUser.id)">
            <v-list-item-title class="error--text"
              ><v-icon color="error" class="mr-2">mdi-karate</v-icon
              >Кикнуть</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-tabs
        slider-color="rgba(0,0,0,0)"
        color="primary"
        v-model="activeTab"
        style="position: sticky; top: 133px; z-index: 2;"
      >
        <v-tab href="#tab-general">Общее</v-tab>
        <v-tab href="#tab-sections">Разделы</v-tab>
        <v-tab href="#tab-reports">Построение отчета</v-tab>
        <v-tab href="#tab-data">Данные</v-tab>
        <v-tab href="#tab-sources">Источники</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item value="tab-general">
          <tab-general :selectedUser="selectedUser" @changeTab="(e)=>activeTab=e"/>
        </v-tab-item>
        <v-tab-item value="tab-sections">
          <tab-sections :selectedUser="selectedUser" privSection="getSectionsPrivs"/>
        </v-tab-item>
        <v-tab-item value="tab-reports">
          <tab-sections :selectedUser="selectedUser" privSection="getReportsPrivs"/>
        </v-tab-item>
        <v-tab-item value="tab-data">
          <tab-data :selectedUser="selectedUser" />
        </v-tab-item>
        <v-tab-item value="tab-sources">
          <tab-sources :selectedUser="selectedUser" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <copy-account-dialog
      :shown="copyAccountDialog"
      :selectedUser="selectedUser"
      @close="copyAccountDialog = false"
    />
    <new-accounts-dialog
      :shown="newAccountsDialog"
      :selectedUser="selectedUser"
      @close="newAccountsDialog = false"
    />
  </v-card>
</template>

<style scoped>
.v-slide-group__prev, .v-slide-group__next {
  display: none !important;
}
.v-btn, .v-tab {
  text-transform: unset !important;
  letter-spacing: 0;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, .54)!important;
  font-size: 14px;
}
.v-tabs a {font-size: 16px;}

.userCardTitle {
  position: sticky; top: 65px; z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  font-weight: 500;
  font-size: 23px;
  line-height: 32px;
/*   background-color: rgba(0,0,0,.06)!important; */
  background-color: #fafafa!important;
}
.userCardTitle-id {
  color: rgba(0, 0, 0, 0.38);
}
.userCardTitle-name {
  flex-grow: 1;
}
.userCardTitle-copy {
  color: rgba(0, 0, 0, 0.38);
}
.userCardTitle-menu {
  cursor: pointer;
}
</style>
<script>
import moment from "moment";
import TabGeneral from '@/components/AdminAccountTabGeneral.vue';
import TabSections from '@/components/AdminAccountTabPrivs.vue';
import TabData from '@/components/AdminAccountTabData.vue';
import TabSources from '@/components/AdminAccountTabSources.vue';
import CopyAccountDialog from "@/components/AdminAccountCopyDialog.vue";
import NewAccountsDialog from "@/components/AdminAccountNewUsersDialog.vue";
import { mapActions } from "vuex";
//import Utils from "../services/utils";

export default {
  components: {
    TabGeneral, TabSections, TabData, TabSources, CopyAccountDialog, NewAccountsDialog,
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data: () => ({
    rolesItems: [
      {id: "", name: "Пользователь"},
      {id: "administrator", name: "Администратор"},
    ],
    activeTab: 0,
    userActive: false,
    copyAccountDialog: false, // открыт диалог копирования аккаунта
    newAccountsDialog: false, // открыт диалог создания новых аккаунтов
  }),
  computed: {
    now() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    ...mapActions(["GetUsers", "KickUser"]),
    async kickUser(userId) {
      await this.KickUser({ id: userId });
      await this.GetUsers();
    },
  },
  watch: {
    selectedUser: function (value) {
      console.log(value);
    },
  },
};
</script>
