<template>
  <div>
    <div class="cardSubheader mt-1">
      Имя, логин, пароль
    </div>
    <v-row class="mt-1">
      <v-col cols="6">
        <v-text-field
          dense
          v-model="user.name"
          outlined
          label="Имя"
          :append-icon="user.name!=user.oldName?'mdi-content-save':''"
          @focusout="(e)=>{ updateField(user.id, 'name', user.oldName) }"
          @click:append="updateUser(user.id, 'name', user.name)"
        />
      </v-col>
      <v-col cols="6">
        <div class="d-flex">
          <v-text-field
            :ref="`pas_${user.id}`"
            dense
            v-model="user.password"
            outlined
            label="Пароль"
            append-icon="mdi-content-save"
            @focusout="(e)=>updateField(user.id, 'password', '********')"
            @click="(e)=> {if(user.password=='********') user.password = '';}"
            @click:append="updateUser(user.id, 'password', user.password)"
          />
          <v-menu offset-y open-on-hover max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                v-on="on"
                fab
                small
                class="pl-0 pr-0 ml-2 pasBtn elevation-0"
                @click="newPassword(user.id)"
              ><v-icon small>mdi-refresh</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-text>Сгенерировать пароль</v-card-text>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-n6">
      <v-col cols="6">
        <v-text-field
          dense
          v-model="user.login"
          outlined
          label="Почта"
          :append-icon="user.login!=user.oldLogin?'mdi-content-save':''"
          @focusout="(e)=>{ updateField(user.id, 'login', user.oldLogin) }"
          @click:append="updateUser(user.id, 'login', user.login)"
        />
      </v-col>
    </v-row>
    <div class="cardSubheader mt-1">
      Сроки доступа <a class="ml-4" href="#" @click="$emit('changeTab', 'tab-data')">редактировать</a>
    </div>
    <v-row class="mt-4">
      <v-col cols="6" class="d-flex">
        <v-icon color="success" v-if="user.maxActiveDt>now && calcAccountActiveDays(user.maxActiveDt)>30">mdi-checkbox-marked-circle</v-icon>
        <v-icon color="warning" v-if="user.maxActiveDt>now && calcAccountActiveDays(user.maxActiveDt)<=30">mdi-checkbox-marked-circle</v-icon>
        <v-icon color="error" v-if="user.maxActiveDt<now">mdi-cancel</v-icon>
        <div class="ml-2" v-if="user.maxActiveDt>now">
          <h4>Аккаунт открыт</h4>
          <div class="tabDescription">
            Еще {{pluralFormDays(calcAccountActiveDays(user.maxActiveDt))}}
          </div>
        </div>
        <div class="ml-2" v-if="user.maxActiveDt<now">
          <h4>Аккаунт закрыт</h4>
          <div class="tabDescription">
            {{user.minActive}} - {{user.maxActive}}
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex" v-if="$store.getters.hasActivePeriod > 0">
        <v-icon color="green" v-if="$store.getters.hasActivePeriod>30">mdi-checkbox-marked-circle</v-icon>
        <v-icon color="warning" v-if="$store.getters.hasActivePeriod<=30">mdi-checkbox-marked-circle</v-icon>
        <div class="ml-2">
          <h4>Данные доступны</h4>
          <div class="tabDescription">
            Еще {{pluralFormDays($store.getters.hasActivePeriod)}}
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex" v-if="!$store.getters.hasActivePeriod">
        <v-icon color="warning">mdi-history</v-icon>
        <div class="ml-2">
          <h4>Данные из истории</h4>
          <div class="tabDescription">
            Всего {{$store.getters.getClientPeriods.length}}
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="cardSubheader mt-4">
      Теги
    </div>
    <v-row class="mt-4">
      <v-col cols="12">
        <tags-input :selectedUser="selectedUser" />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}
.tabDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.58);
}
.cardSubheader {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.58);
}
.pasBtn {
  border-radius: 4px !important;
  border: 1px solid rgba(0,0,0,.87)!important;
}
</style>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import Utils from "../services/utils";
import TagsInput from "@/components/AdminAccountTagsInput.vue";

export default {
  emits: ["changeTab"],
  components: { TagsInput },
  props: {
    selectedUser: {
      type: Object,
      default: () => {
        {return {
          login: "",
          name: "",
        }};
      },
    },
  },
  mounted() {
    this.user = this.selectedUser;
  },
  data: () => ({
    user: null
  }),
  computed: {
    now() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    ...mapActions(["GetUsers", "SaveUser", "AddUser", "KickUser"]),
    pluralFormDays(days) {
      return Utils.pluralForm(days, "день", "дня", "дней");
    },
    calcAccountActiveDays(dt) {
      const start = moment(this.now);
      const end = moment(dt);
      const days = end.diff(start, "days") + 1;
      return days;
    },
    generatePassword() {
      const length = 8;
      const charset = "abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let passwd = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        passwd += charset.charAt(Math.floor(Math.random() * n));
      }
      return passwd;
    },
    newPassword(userId) {
      this.$store.commit("clearUsersEdits");
      this.$store.commit("updateUser", {userId, "password": this.generatePassword()});
    },
    async addUser() {
      if (this.search.length) {
        console.log(`Add: ${this.search}`);
        await this.AddUser({ name: this.search });
      }
    },
    async kickUser(userId) {
      await this.KickUser({ id: userId });
    },
    formatDate(dt) {
      return moment(dt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
    },
    async updateField(userId, field, value) {
      console.log(userId, field, value);
      this.$store.commit("updateUser", {userId, [field]: value});
      //this.editField(userId, null); //reset editing
    },
    async updateUser(userId, field, value) {
      if (field=="maxActive") {
        value = moment(value).format("YYYY-MM-DD") + " 23:59:59";
      }
      if (field=="minActive") {
        value = moment(value).format("YYYY-MM-DD") + " 00:00:00";
      }
      this.$store.commit("updateUser", {userId, [field]: value});
      await this.SaveUser({ id: userId, [field]: value});
     // this.editField(userId, null); //reset editing
    },
    async toggelUserActive(userId) {
      const user = this.$store.getters.getUsers.find(
        (item) => item.id == userId
      );
      const active = this.$store.getters.getUser(userId).active;
      await this.SaveUser({ id: user.id, active });
      //await this.GetUsers();
    },
  },
  watch: {
    selectedUser: function (value) {
      this.user = this.selectedUser;
      console.log(value);
    },
  },
};
</script>
